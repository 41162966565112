import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'react-bootstrap';
import Logo from './pictures/logo-small.png';
import iconBone from './pictures/Bone-icon.svg';
import './css/dropdownProduct.css';
import DropdownProduct from './components/dropdownProduct';

class Navigation extends Component {
  render() {
    return (
      <div id="nav">
			
        <Nav className="navbar navbar-expand-lg navbar-light bg-white menu-popup" >
				<div className="container d-flex flex-nowrap align-items-end">
					<button className="navbar-toggler ml-auto p-2" type="button" data-bs-toggle="offcanvas" 
						data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
							<span className="navbar-toggler-icon"></span>
					</button>
					
					<div className="offcanvas offcanvas-top" tabIndex="-1" id="offcanvasTop" 
						aria-labelledby="offcanvasTopLabel">
						<div className="offcanvas-header block-logo-menu">
							<img
								src={Logo}
								className="img-logo-menu d-inline-block align-top" alt="..."></img>
							<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" 
								aria-label="Close"></button>
						</div>
						<div className="align-items-center menu-max-contain">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-nav-scroll align-items-center ">
								<li>
									<a href="/">
									<img src={Logo}
										className="img-logo d-inline-block align-top" 
										alt="..." width="150px"></img>
								</a>
								</li>
								<li className="nav-item">
									<Link to="/about" aria-current="page" style={{textDecoration: "none", color:"black"}}>
										<h6><i className="bi-person"></i> About Us</h6>
									</Link>
								</li>
								<li className="nav-item">
									<div className="subnav">
											<h6><a href="/products" className="hyperlink-product" >
												<img src={iconBone} height="30" width="30" /> Products</a></h6>
											
									</div>
									
								</li>
								<li className="nav-item">
									<Link to="/contact" aria-current="page" style={{textDecoration: "none", color:"black"}}>
										<h6><i className="bi bi-mailbox"></i> Contact Us</h6>
									</Link>
								</li>
								<li className="nav-item"></li>
								<li className="nav-item"></li>
								<li className="nav-item"></li>
								<li className="nav-item"></li>
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" data-bs-toggle="collapse" 
									href="#collapseSearch" className="bi bi-search" viewBox="0 0 25 25" 
									aria-controls="collapseSearch">
										<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</ul>
							
							
						</div>
					</div>
				</div>
			</Nav>
			<div className="container collapse menu-search-popup" id="collapseSearch">
				<div className="card card-body" style={{marginLeft: 40 + 'em'}}>
					<form className="d-flex">
						<div className="input-group">
							<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</button>
							<ul className="dropdown-menu">
								<li><a className="dropdown-item" href="#x">All</a></li>
								<li><a className="dropdown-item" href="#x">Products</a></li>
								<li><a className="dropdown-item" href="#x">Something else here</a></li>
								<li><hr className="dropdown-divider"></hr></li>
								<li><a className="dropdown-item" href="#x">Separated link</a></li>
							</ul>
							<input type="text" className="form-control" aria-label="Text input with dropdown button"></input>
							<button className="btn btn-outline-success" type="submit">Search</button>
						</div>
						
					</form>
				</div>
			</div>
			<DropdownProduct></DropdownProduct>
      </div>
   );}
}

export default Navigation;