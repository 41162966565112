import React, { useEffect } from 'react';
import HeaderPage from './header-page';
import h_1_1 from './pictures/h-1-1.png';
import cer_logo from './pictures/cer-logo.png';
import Timeline from './components/timline';
import headerBG from './pictures/about-header.jpg';
import AboutM1 from './pictures/cares.png';
import AboutM2 from './pictures/premium_int.png';

function About(){
	useEffect(() => {    
		// Update the document title using the browser API    
		document.title = "About Worldpet | World Pet International";  
	});

	return (
		<div>
			<div className="App-header-page">
				<HeaderPage header="About Us" headerDesc="" 
					headerBG={headerBG}></HeaderPage>
			</div>
			<div className="container app-home">
				<div className="row App-container-min mb-1" >
					<div className="row App-container-min justify-content-md-center" >
						<div class="col col-lg-2 home-min-width">
							<h5 className="card-title">World Pet Cares</h5>
							<hr className="mt-1 mb-3"/>
						</div>
						<div class="container">
							<div class="row justify-content-md-center">
								<div className="col">
									<p className="card-text">For over 30 years of experience in manufacturing dog chews,<br />
									we have been dedicated to deliver products and services that consistently <br />
									satisfy customer needs in various pet markets.<br />
									<br />
									We are committed to continual improvement, which involves workplace, <br />environments and 
									opportunities to enhance innovating, piloting and evolving.<br />
									</p>
									
									<div class="container">
										<div class="row justify-content-md-center">
											<div class="col col-lg-9">
												<img src={AboutM1} className="card-img-top" alt="..."></img>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row App-container-min justify-content-md-center" >
						<div class="col col-lg-3 home-min-width">
							<h5 className="card-title">Premium Ingredients</h5>
							<hr className="mt-1 mb-3"/>
						</div>
						<div class="container">
							<div class="row justify-content-md-center">
								<div className="col">
									<p className="card-text">Our products are made from real meat and natural ingredients.<br />
	We consistently explore the use of new ingredients to serve<br />
	customers' pets with exciting innovative products.
									</p>
									<div class="container">
										<div class="row justify-content-md-center">
											<div class="col col-lg-9">
												<img src={AboutM2} className="card-img-top" alt="..."></img>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row App-container-min justify-content-md-center" >
						<div class="col col-lg-2 home-min-width-1">
							<h5 className="card-title">Innovation & Development</h5>
							<hr className="mt-1 mb-3"/>
						</div>
						<div class="container">
							<div class="row justify-content-md-center">
								<div className="col">
									<p className="card-text">We can bring your quality ideas into reality.<br />
	We develop products alongside with customers to deliver<br />
	innovative and superior products.
									</p>
									<div class="container">
										<div class="row justify-content-md-center">
											<div class="col col-lg-8">
												<img src={h_1_1} className="card-img-top w-50" alt="..."></img>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row App-container-min justify-content-md-center" >
						<div class="col col-lg-2 home-min-width-2">
							<h5 className="card-title">Partnership</h5>
							<hr className="mt-1 mb-3"/>
						</div>
						<div class="container">
							<div class="row justify-content-md-center">
								<div className="col">
									<p className="card-text">We value the collaboration we have with our customers and partners<br />
to consistently deliver the best for your pets.
									</p>
									<div class="container">
										<div class="row justify-content-md-center">
											<div class="col col-lg-8">
												<img src={h_1_1} className="card-img-top w-50" alt="..."></img>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row App-container-min justify-content-md-center" >
						<div class="col col-lg-2 home-min-width-3">
							<h5 className="card-title">Environmental Stewardship</h5>
							<hr className="mt-1 mb-3"/>
						</div>
						<div class="container">
							<div class="row justify-content-md-center">
								<div className="col">
									<p className="card-text">We're committed to helping create a safe, 
									healthy and sustainable world for our <br />customers, partners and the communities.<br />
									</p>
									<p className="card-text">We have implemented to utilize more than 30% renewable 
									electricity used at our <br />site as a part to save our planet. We aim to move forward to a greener factory.
									</p>
									<div class="container">
										<div class="row justify-content-md-center">
											<div class="col col-lg-8">
												<img src={h_1_1} className="card-img-top w-50" alt="..."></img>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


				</div>
				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-2 home-min-width">
						<h5 className="card-title">Our Certification</h5>
						<hr className="mt-1 mb-3"/>
					</div>
					<div class="container">
						<div class="row justify-content-md-center">
							<div className="col">
								<p className="card-text">We are proud to make all products with 
									love under food safety concepts according to <br />international standards.
								</p>
								<div class="container">
									<div class="row justify-content-md-center">
										<div class="col col-lg-6">
											<img src={cer_logo} className="img-logo" alt="..."></img>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<Timeline></Timeline>
				</div>
			</div>
		</div>
	);
}

export default About;