import React from 'react';

function Component(){

	return (
		<div>
			<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content modalPopup-div">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Tell us message</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<form className="row g-1">
								<div className="form-group row">
									<label htmlFor="inputFirstName" className="col-sm-4 col-form-label">I'd like to *</label>
									<div className="col-sm-8">
										<div className="form-check">
											<input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1"></input>
											<label className="form-check-label" htmlFor="gridRadios1">
												Ask a question
											</label>
										</div>
										<div className="form-check">
											<input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2"></input>
											<label className="form-check-label" htmlFor="gridRadios2">
												Provide feedback
											</label>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputFirstName" className="col-sm-4 col-form-label">First Name *</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputFirstName"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputLastName" className="col-sm-4 col-form-label">Last Name *</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputLastName"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputOrganisation" className="col-sm-4 col-form-label">Organisation</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputOrganisation"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputPosition" className="col-sm-4 col-form-label">Position</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputPosition"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputState" className="col-sm-4 col-form-label">State *</label>
									<div className="col-sm-8">
										<select id="inputState" className="form-control">
											<option select="true">Choose...</option>
											<option>THA</option>
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputMethod" className="col-sm-5 col-form-label">Method of contact</label>
									<div className="col-sm-7">
										<select id="inputMethod" className="form-control">
											<option select="true">Choose...</option>
											<option>Phone</option>
											<option>Email</option>
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputEmail" className="col-sm-4 col-form-label">Email *</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputEmail"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputPhone" className="col-sm-4 col-form-label">Phone</label>
									<div className="col-sm-8">
										<input type="text" className="form-control" id="inputPhone"></input>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputWhenCall" className="col-sm-4 col-form-label">When to call</label>
									<div className="col-sm-8">
										<select id="inputWhenCall" className="form-control">
											<option select="true">Choose...</option>
											<option>As soon as possible</option>
											<option>Morning</option>
											<option>Lunchtime</option>
											<option>Afternoon</option>
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="inputMessage" className="col-sm-4 col-form-label">Message *</label>
									<div className="col-sm-8">
										<textarea className="form-control" name="Text1" cols="40" rows="5" id="inputMessage"></textarea>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" className="btn btn-primary">Send message</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Component;