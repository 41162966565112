import React, {useEffect} from 'react';
import Header from './header';
import cer_gmp from './pictures/gmp_logo.jpg';
import cer_fspca from './pictures/fspca_certified_logo.jpg';
import cer_brc from './pictures/brc-food-certificated-logo.png';
import cer_smeta from './pictures/SMETA_logo.png';
import cer_logo from './pictures/cer-logo.png';
import Timeline from './components/timline';
import Bg_compound_chew from './pictures/bg_compound_chew1.jpg';
import Bg_raw_hide from './pictures/bg_raw_hide2.jpg';
import Bg_munchy from './pictures/bg_munchy2.jpg';

function Home(){
	useEffect(() => {    
		// Update the document title using the browser API    
		document.title = "World Pet International";  
	});

	return (
		<div data-bs-offset="0" tabIndex="0">
			<div className="App-header">
				<Header></Header>
			</div>
			<div className="container">
				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-2 home-min-width">
						<h5 className="card-title">Our Certification</h5>
						<hr className="mt-1 mb-3"/>
					</div>
					<div class="container">
						<div class="row justify-content-md-center">
							<div className="col">
								<p className="card-text">We are proud to make all products with 
									love under food safety concepts according to <br />international standards.
								</p>
								<div class="container">
									<div class="row justify-content-md-center">
										<div class="col col-lg-6">
											<img src={cer_logo} className="img-logo" alt="..."></img>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div >
					<Timeline></Timeline>
				</div>
				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-1 home-min-width-2">
						<h5 className="card-title">Our Products</h5>
						<hr className="mt-1 mb-3"/>
					</div>
					<div class="container">
						<div class="row justify-content-md-center home-img-padding">
							<div class="col col-lg-9">
								<img src={Bg_raw_hide} className="img-logo" alt="..."></img>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row justify-content-md-center home-img-padding">
							<div class="col col-lg-9">
								<img src={Bg_munchy} className="img-logo" alt="..."></img>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="row justify-content-md-center home-img-padding">
							<div class="col col-lg-9">
								<img src={Bg_compound_chew} className="img-logo" alt="..."></img>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="row App-container-min" >
					<div className="col">
						<div className="card " >
							<div className="row bg-image p-6 text-white"
								style={{
									backgroundImage: `url(${Bg_compound_chew})`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									height: '70vh'
								}}>
								<div className="col-md-12">
									<div className="row align-items-center">
										<div className="col-md-7 home-header-content">
											<h1 className="display-7 mb-3 header-content-bold">Compound Chews</h1>
											<h5>Chews made from combination of rawhide and real meat</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				

				{/* <div className="row App-container-min" >
					<div className="col">
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Pork Hide</h5>
										<p className="card-text">Chews made from 100% porkhide</p>
										<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									</div>
								</div>
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid rounded-start" alt="..."></img>
								</div>
							</div>
						</div>
					</div>
				</div> */}

			</div>
		</div>
	);
}

export default Home;