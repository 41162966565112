import './css/App.css';
import Home from './home';
import Menu from './menux';
import Footer from './footer';
import PopoverMenu from './components/popoverMenu';
import { Routes ,Route } from 'react-router-dom';
import About from './about';
import Contact from './contact';
import PopoverMoveup from './components/popoverMoveUp';
import ModalPopup from './components/modalPopup';
import Products from './products';
import RawHide from './products/rawHide';
import Munchy from './products/munchy';
import Compound from './products/compound';

function App() {
  return (
    <div className="App">
			<div id="topPage" className="container">
				
			</div>
			<div className="sticky">
				<div className="App-header-logo">
					<div class="container">
						<div class="row align-items-center">
							<div class="col">
								<Menu></Menu>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Routes>
					<Route exact path='/' element={<Home></Home>}  />
					<Route exact path='/about' element={<About />}  />
					<Route exact path='/contact' element={<Contact/>}  />
					<Route exact path='/products' element={<Products />}  />
					<Route exact path='/products_rawHide' element={<RawHide />}  />
					<Route exact path='/products_munchy' element={<Munchy />}  />
					<Route exact path='/products_compound' element={<Compound />}  />
					<Route render={function () {
						return <p>Not found</p>
					}} />
				</Routes>
      	</div>
			<div className="App-footer">
				<Footer></Footer>
			</div>
			<div>
				<PopoverMenu></PopoverMenu>
				<PopoverMoveup></PopoverMoveup>
				<ModalPopup></ModalPopup>
			</div>
    </div>
  );
}

export default App;
