import React from 'react';

function Component(){
	return (
		<div className="btn_gotop">
			<a href="#" className="nav-link active">
				<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="gray" className="bi bi-arrow-up-square-fill" viewBox="0 0 17 17">
					<path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"/>
				</svg>
			</a>
		</div>
	);
}

export default Component;