import React, { useEffect } from 'react';
import HeaderPage from './header-page';
import headerBG from './pictures/contact-header.jpg';
import picVisit from './pictures/picVisit.jpg';

function Contact(){
	useEffect(() => {    
		// Update the document title using the browser API    
		document.title = "Contact Worldpet | World Pet International";  
	});

	return (
		<div>
			<div className="App-header-page">
				<HeaderPage header="Contact Us" 
					headerDesc="Let us know what we can help." headerBG={headerBG}>
				</HeaderPage>
			</div>

			

			<div className="container ">
				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-1 home-min-width-2">
						<h5 className="card-title">Contact Us</h5>
						<hr className="mt-1"/>
					</div>
				</div>

				<div className="row App-container-min justify-content-md-center" >
					<div className="col-10">
						<div className="card border-0">
							<div className="row">
								<div className="col-md-7">
									<div className="card-body">	
										<div className="col-md-12">
											<div className="card-body">
												<h6 className="card-text">Tel. : (66+)2 323 3001 up to 4</h6>
												<h6 className="card-text">Fax : (66+)2 323 3005</h6>
												<h6><a href="http://www.worldpet.co.th" className="card-text">
													Website : http://www.worldpet.co.th</a></h6>
												<h6 className="card-text">
													Email : wikij@worldpet.co.th, nalinee@worldpet.co.th</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-5" style={{
										backgroundImage: `url(${picVisit})`,
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										height: '50vh',
										borderStartStartRadius:'40px',
										borderEndEndRadius:'40px'
									}}>
														
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-1 home-min-width-2">
						<h5 className="card-title">Visit Us</h5>
						<hr className="mt-1"/>
					</div>
				</div>

				<div className="row App-container-min justify-content-md-center" >
					<div class="container">
						<div class="row justify-content-md-center">
							<div className="col-9">
								<div className="row g-0">
								<div className="col-md-5">
									<div className="card-body">
										<p>919 Moo 4, Sukhumvit Road 
											<br/>Bangpoomai, Muang, 
											<br/>Samutprakarn 10280 Thailand</p>
									</div>
								</div>
								<div className="col-md-7 contact-map">
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26096.651011712394!2d100.69024252989665!3d13.517762804406836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd48ddc9c3cde1e6c!2z4LmA4Lin4Li04Lil4LiU4LmM4LmA4Lie4LmH4LiX!5e0!3m2!1sth!2sth!4v1633962237318!5m2!1sth!2sth" 
										width="520" height="450"></iframe>
										
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-1 home-min-width-2">
						<h5 className="card-title">Feedback Us</h5>
						<hr className="mt-1 mb-1"/>
					</div>
				</div>

				<div className="row App-container-min justify-content-md-center" >
					<div className="col-10">
						<div >
							
							<div className="card-body card-bg-collor">
								<div className="row g-0 contact-email-form">
									<p>If you have question, want technical advices or need to give us some feedback, 
										we are here to help.<br/>
										* Mandatory fields
									</p>
									<form className="row g-1">
										<div className="col-12">
											<label htmlFor="inputAddress" className="form-label">I'd like to *</label>
											<div className="form-check">
												<input className="form-check-input" type="radio" name="gridRadios" 
													id="gridRadios1" value="option1"></input>
												<label className="form-check-label" htmlFor="gridRadios1">
													Ask a question
												</label>
											</div>
											<div className="form-check">
												<input className="form-check-input" type="radio" name="gridRadios" 
													id="gridRadios2" value="option2"></input>
												<label className="form-check-label" htmlFor="gridRadios2">
													Provide feedback
												</label>
											</div>
										</div>
										
										<div className="col-6">
											<label htmlFor="inputAddress" className="form-label">First Name *</label>
											<input type="text" className="form-control" id="inputAddress" ></input>
										</div>
										<div className="col-6">
											<label htmlFor="inputAddress" className="form-label">Last Name *</label>
											<input type="text" className="form-control" id="inputAddress"></input>
										</div>
										<div className="col-12">
											<label htmlFor="inputOrganisation" className="form-label">Organisation</label>
											<input type="text" className="form-control" id="inputOrganisation"></input>
										</div>
										<div className="col-12">
											<label htmlFor="inputPosition" className="form-label">Position</label>
											<input type="text" className="form-control" id="inputPosition"></input>
										</div>
										<div className="col-12">
											<label htmlFor="inputState" className="form-label">State *</label>
											<select id="inputState" className="form-control">
												<option select="true">Choose...</option>
												<option>THA</option>
											</select>
										</div>
										<div className="col-12">
											<label htmlFor="inputState" className="form-label">Method of contact</label>
											<select id="inputState" className="form-control">
												<option select="true">Choose...</option>
												<option>Phone</option>
												<option>Email</option>
											</select>
										</div>
										<div className="col-12">
											<label htmlFor="inputEmail4" className="form-label">Email *</label>
											<input type="email" className="form-control" id="inputEmail4"></input>
										</div>
										<div className="col-12">
											<label htmlFor="inputPhone" className="form-label">Phone</label>
											<input type="text" className="form-control" id="inputPhone"></input>
										</div>
										<div className="col-12">
											<label htmlFor="inputWhenCall" className="form-label">When to call</label>
											<select id="inputWhenCall" className="form-control">
												<option select="true">Choose...</option>
												<option>As soon as possible</option>
												<option>Morning</option>
												<option>Lunchtime</option>
												<option>Afternoon</option>
											</select>
										</div>
										<div className="col-12">
											<label htmlFor="inputMessage" className="form-label">Message *</label>
											<textarea className="form-control" name="Text1" cols="40" rows="5" id="inputMessage"></textarea>
										</div>

										<div className="col-12">
											<button type="submit" className="btn btn-primary">Send</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	);
}

export default Contact;