import React from 'react';
import Logo from './pictures/logo.png';
import LogoFB from './pictures/fb-icon.png';

function Footer(){
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-5">
					<a className="navbar-brand" href="/">
						<img
							src={Logo}
							width="160"
							height="70"
							className="d-inline-block align-top" alt="..."></img>
					</a>
				</div>
				<div className="col-md-7">
						<div className="row">
								<div className="col-md-10 text-right">
									<a href="#x">
										<img
											src={LogoFB}
											width="40"
											height="40"
											alt="..."></img>
									</a>
								</div>
						</div>
						<div className="row">
								<div className="col-md-10 text-right">
									<a href="#x" className="hyperlink-color">Privacy policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
									<a href="#x" className="hyperlink-color">Terms & conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;  
									<a href="#x" className="hyperlink-color">Contact</a>&nbsp;&nbsp;|&nbsp;&nbsp; 
									<a href="#x" className="hyperlink-color">Site map</a>
								</div>
						</div>
				</div>
			</div>
			<div className="row justify-content-md-center ">
				<div className="col-md-6 footer-copyright">
					<h5>World Pet International All Right Reserved.</h5>
				</div>
			</div>
		</div>
	);
}

export default Footer;