import React from 'react';

function HeaderPage(props){
	const Header = props.header;
	const HeaderDesc = props.headerDesc;
	const HeaderBG = props.headerBG;

	return (
		<div
				className="bg-image p-4 shadow-1-strong text-white"
				style={{
					backgroundImage: `url(${HeaderBG})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					height: '65vh'
				}}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-8 header-content-border">
							<h1 className="display-4 mb-3 header-content-bold">{Header}</h1>
							<h5>{HeaderDesc}</h5>
						</div>
					</div>
				</div>
		</div>
	);
}

export default HeaderPage;