import React from 'react';

function HeaderPage(props){
	const Header = props.header;
	const HeaderDesc = props.headerDesc;
	const HeaderBG = props.headerBG;

	return (
		<div
				className="bg-image p-3 shadow-1-strong text-white"
				style={{
					backgroundImage: `url(${HeaderBG})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					height: '65vh'
				}}
			>
			<div className="row align-items-center">
				<div className="col-md-8 offset-md-1">
					<h1 className="header-font-head header-content-bold">{Header}</h1>
				</div>
			</div>
			<div className="row align-items-center">
				<div className="col-md-5 offset-md-1">
					<h5>{HeaderDesc}</h5>
				</div>
			</div>
		</div>
	);
}

export default HeaderPage;