import React, {useEffect} from 'react';
import HeaderPage from '../header-products';
import h_1_1 from '../pictures/h-1-1.png';
import headerBG from '../pictures/bg_munchy.jpg';

function Products(){
	useEffect(() => {    
		// Update the document title using the browser API    
		document.title = "Products Worldpet | World Pet International";  
	});

	return (
		<div>
			<div className="App-header-page">
				<HeaderPage header="Munchy" headerDesc="Apart from the five product categories, we can also 
				collaborate with customers to deliver services and product solutions customized to customer needs." 
				headerBG={headerBG}></HeaderPage>
			</div>
			
			<div className="container" data-bs-spy="scroll" data-bs-offset="0" tabIndex="0">
				<div className="row App-container-min" >
					<div className="col">
						<div className="card border-bottom-0" >
							<div className="row g-0">
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid" alt="..."></img>
								</div>
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Card title</h5>
										<p className="card-text">Chews made from 100% rawhide</p>
									</div>
								</div>
							</div>
						</div>
						<div className="card border-bottom-0" >
							<div className="row g-0">
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Card title</h5>
										<p className="card-text">Chews made from 100% rawhide</p>
									</div>
								</div>
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid" alt="..."></img>
								</div>
							</div>
						</div>
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid" alt="..."></img>
								</div>
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Card title</h5>
										<p className="card-text">Chews made from 100% rawhide</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default Products;