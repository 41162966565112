import React from 'react';
import '../css/dropdownProduct.css';
import iconPro from '../pictures/box_PNG60.png';
import iconBone from '../pictures/Bone-icon.svg';

function Component(){
	/* useEffect(() => {    
		window.jsProducts();
	}); */
	return (
		<div id="pop1" className="subnav-content">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-1 md-2">
						<div className="row">
							<div className="col-8 col-sm-6">
								<span className="carousel-control-prev-icon" id="prev" aria-hidden="true"></span>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="thumbnailgallery">
							<div className="showrooms "> 
								<a id="m1" href="/products_rawHide" className="logo" style={{textDecoration: "none"}}>
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Raw Hide</p>
								</a>
								<a id="m2" href="/products_munchy" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Munchy</p>
								</a>
								<a id="m3" href="/products_compound" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Compound Chews</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Raw-hide free chews</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Pressed bone</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Chew stick</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Knotted bone</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Fancy product</p>
								</a>
								<a href="#" className="logo">
									<img src={iconPro} className="img-icon" alt=".."></img>
									<p className="text-border">Chicken Meat</p>
								</a>
							</div>
						</div>
					</div>
					<div className="col-1">
						<span className="carousel-control-next-icon" id="next" aria-hidden="true"></span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Component;