import React, {useEffect} from 'react';
import HeaderPage from './header-page';
import h_1_1 from './pictures/h-1-1.png';
import headerBG from './pictures/product-header1.jpg';

function Products(){
	useEffect(() => {    
		// Update the document title using the browser API    
		document.title = "Products Worldpet | World Pet International";  
	});

	return (
		<div>
			<div className="App-header-page">
				<HeaderPage header="Products" headerDesc="Envision the future dog treats with us." 
				headerBG={headerBG}></HeaderPage>
			</div>
			
			<div className="block-logo-menu">
				<div className="container justify-content-center products-container">
					<div className="container">
						<div className="row">
							<div className="col">
								<a href="/products_rawHide"><div className="shadow-sm border bg-light products-group-menu">Raw Hide</div></a>
							</div>
							<div className="col">
								<a href="/products_munchy"><div className="shadow-sm border bg-light products-group-menu">Munchy</div></a>
							</div>
							<div className="col">
								<a href="/products_compound"><div className="shadow-sm border bg-light products-group-menu">Compound Chews</div></a>
							</div>
							<div className="col">
								<div className="shadow-sm border bg-light products-group-menu">Plant-based</div>
							</div>
							<div className="col">
								<div className="shadow-sm border bg-light products-group-menu">Pork Hide</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row App-container-min justify-content-md-center" >
					<div class="col col-lg-2 home-min-width">
						<h5 className="card-title">Our Capability</h5>
						<hr className="mt-1 mb-3"/>
					</div>
					<div class="container">
						<div class="row justify-content-md-center">
							<div className="col">
								<p className="card-text">Apart from the five product categories, we also collaborate<br />
with customers to deliver services and product solutions<br />
customized to customer needs.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="row App-container-min" >
					<div className="col">
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid rounded-start" alt="..."></img>
								</div>
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Munchy</h5>
										<p className="card-text">Chews made from grounded rawhide mixed with starch</p>
										<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row App-container-min" >
					<div className="col">
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Compound Chews</h5>
										<p className="card-text">Chews made from combination of rawhide and real meat</p>
										<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									</div>
								</div>
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid rounded-start" alt="..."></img>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row App-container-min" >
					<div className="col">
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid rounded-start" alt="..."></img>
								</div>
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Plant-based Rawhide</h5>
										<p className="card-text">Rawhide-free Chews made from mixture of various type of flour and starch</p>
										<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row App-container-min" >
					<div className="col">
						<div className="card mb-3" >
							<div className="row g-0">
								<div className="col-md-8">
									<div className="card-body">
										<h5 className="card-title">Pork Hide</h5>
										<p className="card-text">Chews made from 100% porkhide</p>
										<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									</div>
								</div>
								<div className="col-md-4">
									<img src={h_1_1} className="img-fluid rounded-start" alt="..."></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default Products;