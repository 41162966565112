import React from 'react';
import headerBG from './pictures/header-bg2.jpg';

function Header(){
	return (
			<div
				className="bg-image p-3 shadow-1-strong text-white"
				style={{
					backgroundImage: `url(${headerBG})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					height: '65vh'
				}}
			>
			<br></br>
			<br></br>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-8">
							<h5 className="display-4 header-content-bold">Welcome to World Pet!</h5>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-6 header-content-indent">
							<h6>For over 30 years of experience in manufacturing 
								pet chews and compound chews, 
								we have been dedicated to deliver products and 
								services that consistently resolve customer needs to 
								each of the pet markets we serve.</h6>
						</div>
					</div>
				</div>
			</div>
	);
}

export default Header;