import React from 'react';
import '../css/timline.css';

function Component(){
	return (
		<div className="container ">
			<button className="btn btn-secondary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
					See Timeline
				</button>
			<div className="collapse" id="collapseExample">
				<div className="card card-body border-0">
					<div className="row justify-content-md-center">
							<div className="col-md-9">
									<div className="main-timeline7">
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-mobile"></i></div>
												<span className="year">1992</span>
												<div className="timeline-content">
														<h5 className="title">Establishment</h5>
														<p className="description">
																<br/>
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-briefcase"></i></div>
												<span className="year">2002</span>
												<div className="timeline-content">
														<h5 className="title">Treat Line Expansion</h5>
														<p className="description">
																Compound Chews
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-rocket"></i></div>
												<span className="year">2003</span>
												<div className="timeline-content">
														<h5 className="title">GMP&HACCP Certified</h5>
														<p className="description">
																<br/>
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-globe"></i></div>
												<span className="year">2013</span>
												<div className="timeline-content">
														<h5 className="title">Pet season Establishment</h5>
														<p className="description">
																○	Spin off a business unit "Pet Season" to support premium product lines<br/>
																○	Treat Line Expansion - Plant-based rawhide
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-rocket"></i></div>
												<span className="year">2015</span>
												<div className="timeline-content">
														<h5 className="title">BCP Certified</h5>
														<p className="description">
																<br/>
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-rocket"></i></div>
												<span className="year">2016</span>
												<div className="timeline-content">
														<h5 className="title">SMETA Certified</h5>
														<p className="description">
																<br/>
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-rocket"></i></div>
												<span className="year">2017</span>
												<div className="timeline-content">
														<h5 className="title">FSMA Certified</h5>
														<p className="description">
																<br/>
														</p>
												</div>
										</div>
										<div className="timeline">
												<div className="timeline-icon"><i className="fa fa-rocket"></i></div>
												<span className="year">2018</span>
												<div className="timeline-content">
														<h5 className="title">Treat Line Expansion - Pork Hide Chews</h5>
														<p className="description">
																
														</p>
												</div>
										</div>
									</div>
							</div>
					</div>
				</div>
			</div>
	</div>
	);
}

export default Component;